<script lang="ts" setup>
import { __ } from '@@/bits/intl'
import type { OzBaseDialogBoxButtonData } from '@@/library/v4/components/OzBaseDialogBox.vue'
import OzBaseDialogBox, { OzContainedButtonColorScheme } from '@@/library/v4/components/OzBaseDialogBox.vue'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    darkMode?: boolean | 'auto'
    iconSrc?: string | null
    iconAlt?: string | null
    title: string
    body?: string
    xShadow?: boolean
    closeButtonText?: string
    htmlText?: string
  }>(),
  {
    darkMode: 'auto',
    iconSrc: null,
    iconAlt: null,
    xShadow: false,
    body: undefined,
    closeButtonText: undefined,
    htmlText: undefined,
  },
)

const emit = defineEmits(['close'])

const buttons = computed((): OzBaseDialogBoxButtonData[] => {
  const closeButton = {
    text: props.closeButtonText || __('Okay'),
    emit: 'close',
    colorScheme: OzContainedButtonColorScheme.Secondary,
    testId: 'closeButton',
  }
  return [closeButton]
})

const ariaDescribedBy = computed(() => {
  const ids: string[] = []
  if (props.body || props.htmlText) ids.push('alert-dialog-body')
  return ids.join(' ')
})

const close = (): void => {
  emit('close')
}
</script>

<template>
  <OzBaseDialogBox
    role="alertdialog"
    :class="xShadow && 'shadow-elevation-5'"
    :dark-mode="darkMode"
    :buttons="buttons"
    :force-full-width-buttons="true"
    aria-labelledby="alert-dialog-title"
    :aria-describedby="ariaDescribedBy"
    @close="close"
  >
    <div class="px-4 py-6 font-sans text-center">
      <img v-if="iconSrc" :src="iconSrc" width="64" height="64" :alt="iconAlt || ''" class="select-none pb-2.5" />
      <h2 id="alert-dialog-title" class="font-semibold text-heading-3 break-words px-4 pb-2.5">{{ title }}</h2>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-if="htmlText" id="alert-dialog-body" class="px-8 text-body-small" v-html="htmlText" />
      <p v-else-if="body" id="alert-dialog-body" class="px-8 text-body-small">{{ body }}</p>
    </div>
  </OzBaseDialogBox>
</template>
