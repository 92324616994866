<script setup lang="ts">
/**
 * @file This component comes as a pair with the global_alert_dialog Pinia store.
 */
import OzAlertDialogBox from '@@/library/v4/components/OzAlertDialogBox.vue'
import OzOverlay from '@@/library/v4/components/OzOverlay.vue'
import { useGlobalAlertDialogStore } from '@@/pinia/global_alert_dialog'
import { storeToRefs } from 'pinia'

const globalAlertDialogStore = useGlobalAlertDialogStore()
const { isOpen, alertDialogData } = storeToRefs(globalAlertDialogStore)
const { closeAlertDialog } = globalAlertDialogStore
</script>

<script lang="ts">
export default {}
</script>

<template>
  <OzOverlay
    v-if="isOpen"
    data-testid="globalAlertDialog"
    class="flex justify-center items-center"
    :z-index-class="'z-alert'"
    :should-fade-in="alertDialogData.shouldFadeIn"
    @scrim-click="closeAlertDialog"
    @scrim-esc="closeAlertDialog"
  >
    <OzAlertDialogBox
      :icon-src="alertDialogData.iconSrc"
      :icon-alt="alertDialogData.iconAlt"
      :title="alertDialogData.title"
      :body="alertDialogData.body"
      :x-shadow="alertDialogData.xShadow"
      :close-button-text="alertDialogData.closeButtonText"
      @close="closeAlertDialog"
    />
  </OzOverlay>
</template>
